<template>
  <b-container
    class="mb-5"
    fluid
  >
    <b-breadcrumb
      v-if="$route.meta.breadcrumb"
      :items="$route.meta.breadcrumb || []"
    />
    <b-card>
      <b-row class="mb-2">

        <b-col
          cols="12"
          sm="6"
        >
          <b-form-group>
            <label for="filter_cluster">Filter By Cluster</label>
            <v-select
              id="filter_cluster"
              v-model="selected.filter_cluster"
              :options="list.clusters"
              label="cluster_name"
              placeholder="-- Please Select Cluster --"
            >
              <template #option="{ cluster_name }">
                <span>{{ cluster_name }}</span>
              </template>
              <template #no-options="">
                No Available Clusters
              </template>
            </v-select>
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          sm="6"
        >

          <b-form-group>
            <label for="filter_area">Filter By Area</label>
            <v-select
              id="filter_area"
              v-model="selected.filter_area"
              :options="list.filter_areas"
              label="area_name"
              placeholder="-- Please Select Area --"
            >
              <template #option="{ area_name }">
                <span>{{ area_name }}</span>
              </template>
              <template #no-options="">
                No Available Areas
              </template>
            </v-select>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
        >
          <b-form-group>
            <label>Search String</label>
            <b-input
              v-model="tableSettings.filter"
              type="text"
              placeholder="Enter search string"
              debounce="1000"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-button
            class="mr-1"
            variant="secondary"
            @click="onShowImportDataForm"
          >
            Import Data
          </b-button>
          <b-button
            type="button"
            variant="success"
            @click="onShowAddForm"
          >
            Add Dealer
          </b-button>
        </b-col>

      </b-row>

      <b-table
        ref="table"
        hover
        responsive
        class="mt-2"
        :per-page="tableSettings.perPage"
        :current-page="tableSettings.currentPage"
        :items="myProvider"
        :fields="tableSettings.fields"
        :sort-by.sync="tableSettings.sortBy"
        :sort-desc.sync="tableSettings.sortDesc"
        :sort-direction="tableSettings.sortDirection"
        :filter="tableSettings.filter"
        :filter-included-fields="tableSettings.filterOn"
        show-empty
      >
        <template #cell(index)="data">
          {{ tableSettings.currentPage * tableSettings.perPage - tableSettings.perPage + (data.index + 1) }}
        </template>

        <template #cell(active)="data">
          {{ data.item.active == 1 ? 'Yes' : 'No' }}
        </template>

        <template #cell()="data">
          <div class="text-nowrap">
            {{ data.value }}
          </div>
        </template>

        <template #cell(actions)="row">
          <div class="text-nowrap">
            <b-button
              size="sm"
              @click="onShowEditForm(row.item)"
            >
              Edit
            </b-button>
          </div>
        </template>

      </b-table>

      <b-row>
        <b-col
          cols="12"
          sm="6"
        >
          <div class="w-100 w-sm-25 mb-2 sm-mb-2">
            <b-select
              v-model="tableSettings.perPage"
              :options="tableSettings.pageOptions"
              size="sm"
            />
          </div>
        </b-col>
        <b-col
          cols="12"
          sm="6"
          class="d-flex justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="tableSettings.currentPage"
            :total-rows="tableSettings.totalRows"
            :per-page="tableSettings.perPage"
            first-number
            last-number
            pills
            prev-text="Prev"
            next-text="Next"
            aria-controls="table"
          />
        </b-col>
      </b-row>
    </b-card>

    <b-modal
      id="modal-dealer-form"
      scrollable
      no-close-on-backdrop
      :title="modalTitle"
      size="xl"
      @ok="onConfirmAction"
    >
      <ValidationObserver
        ref="form"
      >
        <form
          role="form"
          novalidate
        >
          <b-tabs
            class="px-2"
            no-body
          >
            <b-tab
              title="Basic Information"
              active
            >
              <b-row>
                <b-col
                  cols="12"
                  sm="6"
                >
                  <ValidationProvider
                    #default="{ errors }"
                    name="status"
                    vid="status"
                    rules="required"
                  >
                    <b-form-group>
                      <label for="status"><strong>Status</strong></label>
                      <b-form-select
                        id="status"
                        v-model="dealer.status"
                        :options="options.statuses"
                        :state="errors.length > 0 ? false : null"
                        :disabled="state.busy"
                      >
                        <template #first>
                          <b-form-select-option
                            :value="null"
                            disabled
                          >
                            -- Please Select Status --
                          </b-form-select-option>
                        </template>
                      </b-form-select>
                      <div
                        v-if="errors.length > 0"
                        class="invalid-feedback"
                      >
                        <span>{{ errors[0] }}</span>
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>

                <b-col
                  cols="12"
                  sm="6"
                >
                  <ValidationProvider
                    #default="{ errors }"
                    name="partner code"
                    vid="partner_code"
                    rules="required|min:7|max:7"
                  >
                    <b-form-group>
                      <label for="partner_code"><strong>Partner Code</strong></label>
                      <b-input
                        id="partner_code"
                        v-model="dealer.partner_code"
                        type="text"
                        :state="errors.length > 0 ? false : null"
                        placeholder="enter partner code"
                        autocomplete="off"
                        :disabled="state.busy"
                      />
                      <div
                        v-if="errors.length > 0"
                        class="invalid-feedback"
                      >
                        <span>{{ errors[0] }}</span>
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>

                <b-col
                  cols="12"
                >
                  <ValidationProvider
                    #default="{ errors }"
                    name="partner name"
                    vid="partner_name"
                    rules="required|max:100"
                  >
                    <b-form-group>
                      <label for="partner_name"><strong>Partner Name</strong></label>
                      <b-input
                        id="partner_name"
                        v-model="dealer.partner_name"
                        type="text"
                        :state="errors.length > 0 ? false : null"
                        placeholder="enter partner name"
                        autocomplete="off"
                        :disabled="state.busy"
                      />
                      <div
                        v-if="errors.length > 0"
                        class="invalid-feedback"
                      >
                        <span>{{ errors[0] }}</span>
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>

                <b-col
                  cols="12"
                  sm="4"
                >
                  <ValidationProvider
                    #default="{ errors }"
                    name="cluster"
                    vid="cluster"
                    rules="required"
                  >
                    <b-form-group>
                      <label for="cluster"><strong>Cluster</strong></label>
                      <v-select
                        id="cluster"
                        v-model="selected.cluster"
                        :options="list.clusters"
                        :class="`${errors.length > 0 ? 'is-invalid' : ''}`"
                        label="cluster_name"
                        :disabled="state.busy"
                      >
                        <template #option="{ cluster_name }">
                          <strong>{{ cluster_name }}</strong>
                        </template>
                        <template #no-options="">
                          No Available Clusters
                        </template>
                      </v-select>
                      <div
                        v-if="errors.length > 0"
                        class="invalid-feedback"
                      >
                        <span>{{ errors[0] }}</span>
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>

                <b-col
                  cols="12"
                  sm="4"
                >
                  <ValidationProvider
                    #default="{ errors }"
                    name="areas"
                    vid="areas"
                    rules="required"
                  >
                    <b-form-group>
                      <label for="area"><strong>Area</strong></label>
                      <v-select
                        id="area"
                        v-model="selected.area"
                        :options="list.areas"
                        :class="`${errors.length > 0 ? 'is-invalid' : ''}`"
                        label="area_name"
                        :disabled="state.busy"
                      >
                        <template #option="{ area_name }">
                          <strong>{{ area_name }}</strong>
                        </template>
                        <template #no-options="">
                          No Available Areas
                        </template>
                      </v-select>
                      <div
                        v-if="errors.length > 0"
                        class="invalid-feedback"
                      >
                        <span>{{ errors[0] }}</span>
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>

                <b-col
                  cols="12"
                  sm="4"
                >
                  <ValidationProvider
                    #default="{ errors }"
                    name="distribution"
                    vid="distribution"
                    rules="required"
                  >
                    <b-form-group>
                      <label for="distribution"><strong>Distribution</strong></label>
                      <v-select
                        id="distribution"
                        v-model="selected.distribution"
                        :options="list.distributions"
                        :class="`${errors.length > 0 ? 'is-invalid' : ''}`"
                        label="distribution_name"
                        :disabled="state.busy"
                      >
                        <template #option="{ distribution_name }">
                          <strong>{{ distribution_name }}</strong>
                        </template>
                        <template #no-options="">
                          No Available Distributions
                        </template>
                      </v-select>
                      <div
                        v-if="errors.length > 0"
                        class="invalid-feedback"
                      >
                        <span>{{ errors[0] }}</span>
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>

                <b-col cols="12">
                  <b-form-group>
                    <label for="business_address"><strong>Business Address</strong></label>
                    <b-input
                      id="business_address"
                      v-model="dealer.business_address"
                      type="text"
                      placeholder="enter business address"
                      autocomplete="off"
                    />
                  </b-form-group>
                </b-col>

                <b-col
                  cols="12"
                  sm="4"
                >
                  <ValidationProvider
                    #default="{ errors }"
                    name="province"
                    vid="province_one"
                    rules=""
                  >
                    <b-form-group>
                      <label for="province_one"><strong>Province (Business Address)</strong></label>
                      <v-select
                        id="province_one"
                        v-model="selected.province_one"
                        :options="list.provinces"
                        :class="`${errors.length > 0 ? 'is-invalid' : ''}`"
                        label="province_name"
                        :disabled="state.busy"
                      >
                        <template #option="{ province_name }">
                          <strong>{{ province_name }}</strong>
                        </template>
                        <template #no-options="">
                          No Available Provinces
                        </template>
                      </v-select>
                      <div
                        v-if="errors.length > 0"
                        class="invalid-feedback"
                      >
                        <span>{{ errors[0] }}</span>
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>

                <b-col
                  cols="12"
                  sm="4"
                >
                  <ValidationProvider
                    #default="{ errors }"
                    name="municipality"
                    vid="municipality_one"
                    rules=""
                  >
                    <b-form-group>
                      <label for="municipality_one"><strong>Municipality (Business Address)</strong></label>
                      <v-select
                        id="municipality_one"
                        v-model="selected.municipality_one"
                        :options="list.municipalities_one"
                        :class="`${errors.length > 0 ? 'is-invalid' : ''}`"
                        label="municipality_name"
                        :disabled="state.busy"
                      >
                        <template #option="{ municipality_name }">
                          <strong>{{ municipality_name }}</strong>
                        </template>
                        <template #no-options="">
                          No Available Municipalities
                        </template>
                      </v-select>
                      <div
                        v-if="errors.length > 0"
                        class="invalid-feedback"
                      >
                        <span>{{ errors[0] }}</span>
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>

                <b-col
                  cols="12"
                  sm="4"
                >
                  <ValidationProvider
                    #default="{ errors }"
                    name="barangay"
                    vid="barangay_one"
                    rules=""
                  >
                    <b-form-group>
                      <label for="barangay_one"><strong>Barangay (Business Address)</strong></label>
                      <v-select
                        id="barangay_one"
                        v-model="selected.barangay_one"
                        :options="list.barangays_one"
                        :class="`${errors.length > 0 ? 'is-invalid' : ''}`"
                        label="barangay_name"
                        :disabled="state.busy"
                      >
                        <template #option="{ barangay_name }">
                          <strong>{{ barangay_name }}</strong>
                        </template>
                        <template #no-options="">
                          No Available Barangays
                        </template>
                      </v-select>
                      <div
                        v-if="errors.length > 0"
                        class="invalid-feedback"
                      >
                        <span>{{ errors[0] }}</span>
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>

                <b-col cols="12">
                  <b-form-group>
                    <label for="home_address"><strong>Home Address</strong></label>
                    <b-input
                      id="home_address"
                      v-model="dealer.home_address"
                      type="text"
                      placeholder="enter home address"
                      autocomplete="off"
                    />
                  </b-form-group>
                </b-col>

                <b-col
                  cols="12"
                  sm="4"
                >
                  <ValidationProvider
                    #default="{ errors }"
                    name="province"
                    vid="province_two"
                    rules=""
                  >
                    <b-form-group>
                      <label for="province_two"><strong>Province (Home Address)</strong></label>
                      <v-select
                        id="province_two"
                        v-model="selected.province_two"
                        :options="list.provinces"
                        :class="`${errors.length > 0 ? 'is-invalid' : ''}`"
                        label="province_name"
                        :disabled="state.busy"
                      >
                        <template #option="{ province_name }">
                          <strong>{{ province_name }}</strong>
                        </template>
                        <template #no-options="">
                          No Available Provinces
                        </template>
                      </v-select>
                      <div
                        v-if="errors.length > 0"
                        class="invalid-feedback"
                      >
                        <span>{{ errors[0] }}</span>
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>

                <b-col
                  cols="12"
                  sm="4"
                >
                  <ValidationProvider
                    #default="{ errors }"
                    name="municipality"
                    vid="municipality_two"
                    rules=""
                  >
                    <b-form-group>
                      <label for="municipality_two"><strong>Municipality (Home Address)</strong></label>
                      <v-select
                        id="municipality_two"
                        v-model="selected.municipality_two"
                        :options="list.municipalities_two"
                        :class="`${errors.length > 0 ? 'is-invalid' : ''}`"
                        label="municipality_name"
                        :disabled="state.busy"
                      >
                        <template #option="{ municipality_name }">
                          <strong>{{ municipality_name }}</strong>
                        </template>
                        <template #no-options="">
                          No Available Municipalities
                        </template>
                      </v-select>
                      <div
                        v-if="errors.length > 0"
                        class="invalid-feedback"
                      >
                        <span>{{ errors[0] }}</span>
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>

                <b-col
                  cols="12"
                  sm="4"
                >
                  <ValidationProvider
                    #default="{ errors }"
                    name="barangay"
                    vid="barangay_two"
                    rules=""
                  >
                    <b-form-group>
                      <label for="barangay_two"><strong>Barangay (Home Address)</strong></label>
                      <v-select
                        id="barangay_two"
                        v-model="selected.barangay_two"
                        :options="list.barangays_two"
                        :class="`${errors.length > 0 ? 'is-invalid' : ''}`"
                        label="barangay_name"
                        :disabled="state.busy"
                      >
                        <template #option="{ barangay_name }">
                          <strong>{{ barangay_name }}</strong>
                        </template>
                        <template #no-options="">
                          No Available Barangays
                        </template>
                      </v-select>
                      <div
                        v-if="errors.length > 0"
                        class="invalid-feedback"
                      >
                        <span>{{ errors[0] }}</span>
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>

                <b-col cols="12">
                  <div
                    v-if="state.editing"
                    class="form-group"
                  >
                    <b-form-checkbox
                      id="is_active"
                      v-model="dealer.active"
                      name="is_active"
                      value="1"
                      unchecked-value="0"
                      :disabled="state.busy"
                    >
                      Is Active?
                    </b-form-checkbox>
                  </div>
                </b-col>
              </b-row>
            </b-tab>

            <b-tab
              title="Contact Persons"
            >
              <b-row>
                <b-col
                  cols="12"
                  sm="6"
                >
                  <b-form-group>
                    <label for="name"><strong>Name</strong> <span class="text-danger">*</span></label>
                    <b-input
                      id="name"
                      v-model="representative.name"
                      type="text"
                      placeholder="enter name"
                      autocomplete="off"
                    />
                  </b-form-group>
                </b-col>

                <b-col
                  cols="12"
                  sm="6"
                >
                  <b-form-group>
                    <label for="birth_date"><strong>Birth Date</strong></label>
                    <b-input
                      id="birth_date"
                      v-model="representative.birth_date"
                      type="date"
                      placeholder="enter birth date"
                      autocomplete="off"
                    />
                  </b-form-group>
                </b-col>

                <b-col
                  cols="12"
                  sm="6"
                >
                  <b-form-group>
                    <label for="phone_one"><strong>Telephone 1</strong></label>
                    <b-input
                      id="phone_one"
                      v-model="representative.phone_one"
                      type="text"
                      placeholder="enter phone number"
                      maxlength="20"
                      autocomplete="off"
                    />
                  </b-form-group>
                </b-col>

                <b-col
                  cols="12"
                  sm="6"
                >
                  <b-form-group>
                    <label for="phone_two"><strong>Telephone 2</strong></label>
                    <b-input
                      id="phone_two"
                      v-model="representative.phone_two"
                      type="text"
                      placeholder="enter phone number"
                      maxlength="20"
                      autocomplete="off"
                    />
                  </b-form-group>
                </b-col>

                <b-col
                  cols="12"
                  sm="6"
                >
                  <b-form-group>
                    <label for="mobile_one"><strong>Mobile 1</strong> <span class="text-danger">*</span></label>
                    <b-input
                      id="mobile_one"
                      v-model="representative.mobile_one"
                      type="text"
                      placeholder="enter mobile number"
                      maxlength="11"
                      autocomplete="off"
                    />
                  </b-form-group>
                </b-col>

                <b-col
                  cols="12"
                  sm="6"
                >
                  <b-form-group>
                    <label for="mobile_two"><strong>Mobile 2</strong></label>
                    <b-input
                      id="mobile_two"
                      v-model="representative.mobile_two"
                      type="text"
                      placeholder="enter mobile number"
                      maxlength="11"
                      autocomplete="off"
                    />
                  </b-form-group>
                </b-col>

                <b-col
                  cols="12"
                  sm="6"
                >
                  <b-form-group>
                    <label for="email"><strong>Email</strong></label>
                    <b-input
                      id="email"
                      v-model="representative.email"
                      type="email"
                      placeholder="enter email"
                      maxlength="100"
                      autocomplete="off"
                    />
                  </b-form-group>
                </b-col>

                <b-col
                  cols="12"
                  sm="6"
                >
                  <b-form-group>
                    <label for="viber"><strong>Viber</strong></label>
                    <b-input
                      id="viber"
                      v-model="representative.viber"
                      type="text"
                      placeholder="enter viber"
                      maxlength="50"
                      autocomplete="off"
                    />
                  </b-form-group>
                </b-col>

                <b-col cols="12">
                  <b-button
                    class="mr-2"
                    type="button"
                    :variant="representativeButtonVariant"
                    :disabled="representativeButtonDisabledState"
                    @click="onRepresentativeButtonClick"
                  >
                    {{ representativeButtonLabel }}
                  </b-button>
                  <b-button
                    v-if="state.representativeEditing"
                    type="button"
                    variant="danger"
                    @click="onRepresentativeCancelUpdate"
                  >
                    Cancel Update
                  </b-button>
                </b-col>

                <b-col
                  cols="12"
                  class="mt-2"
                >
                  <b-table
                    ref="table-crop-details"
                    hover
                    responsive
                    show-empty
                    :fields="tableRepresetativeSettings.fields"
                    :items="filteredRepresentatives"
                  >
                    <template #cell(index)="data">
                      {{ tableRepresetativeSettings.currentPage * tableRepresetativeSettings.perPage - tableRepresetativeSettings.perPage + (data.index + 1) }}
                    </template>

                    <template #cell()="data">
                      <div class="text-nowrap">
                        {{ data.value }}
                      </div>
                    </template>

                    <template #cell(actions)="row">
                      <div class="text-nowrap">
                        <b-button
                          size="sm"
                          variant="success"
                          class="mr-2"
                          :disabled="state.representativeEditing"
                          @click="onRepresentativeEdit(row.item)"
                        >
                          Edit
                        </b-button>

                        <b-button
                          v-if="isNaN(parseInt(row.item.id))"
                          size="sm"
                          variant="danger"
                          :disabled="state.representativeEditing"
                          @click="onRepresentativeRemove(row.item)"
                        >
                          Remove
                        </b-button>
                      </div>
                    </template>
                  </b-table>
                </b-col>
              </b-row>
            </b-tab>

            <b-tab
              title="Stores"
            >
              <b-row>
                <b-col
                  cols="12"
                >
                  <b-form-group>
                    <label for="store_name"><strong>Store Name</strong> <span class="text-danger">*</span></label>
                    <b-input
                      id="store_name"
                      v-model="store.store_name"
                      type="text"
                      placeholder="enter store name"
                      maxlength="255"
                      autocomplete="off"
                    />
                  </b-form-group>
                </b-col>

                <b-col
                  cols="12"
                >
                  <b-form-group>
                    <label for="store_address"><strong>Store Address</strong> <span class="text-danger">*</span></label>
                    <b-input
                      id="store_address"
                      v-model="store.store_address"
                      type="text"
                      placeholder="enter store address"
                      maxlength="255"
                      autocomplete="off"
                    />
                  </b-form-group>
                </b-col>

                <b-col
                  cols="12"
                  sm="4"
                >
                  <b-form-group>
                    <label for="province_three"><strong>Province</strong></label>
                    <v-select
                      id="province_three"
                      v-model="selected3.province"
                      :options="list.provinces"
                      label="province_name"
                    >
                      <template #option="{ province_name }">
                        <strong>{{ province_name }}</strong>
                      </template>
                      <template #no-options="">
                        No Available Provinces
                      </template>
                    </v-select>
                  </b-form-group>
                </b-col>

                <b-col
                  cols="12"
                  sm="4"
                >
                  <b-form-group>
                    <label for="municipality_three"><strong>Municipality</strong></label>
                    <v-select
                      id="municipality_three"
                      v-model="selected3.municipality"
                      :options="list.municipalities_three"
                      label="municipality_name"
                    >
                      <template #option="{ municipality_name }">
                        <strong>{{ municipality_name }}</strong>
                      </template>
                      <template #no-options="">
                        No Available Municipalities
                      </template>
                    </v-select>
                  </b-form-group>
                </b-col>

                <b-col
                  cols="12"
                  sm="4"
                >
                  <b-form-group>
                    <label for="barangay_three"><strong>Barangay</strong></label>
                    <v-select
                      id="barangay_three"
                      v-model="selected3.barangay"
                      :options="list.barangays_three"
                      label="barangay_name"
                    >
                      <template #option="{ barangay_name }">
                        <strong>{{ barangay_name }}</strong>
                      </template>
                      <template #no-options="">
                        No Available Barangays
                      </template>
                    </v-select>
                  </b-form-group>
                </b-col>

                <b-col cols="12">
                  <b-button
                    class="mr-2"
                    type="button"
                    :variant="storeButtonVariant"
                    :disabled="storeButtonDisabledState"
                    @click="onStoreButtonClick"
                  >
                    {{ storeButtonLabel }}
                  </b-button>
                  <b-button
                    v-if="state.storeEditing"
                    type="button"
                    variant="danger"
                    @click="onStoreCancelUpdate"
                  >
                    Cancel Update
                  </b-button>
                </b-col>

                <b-col
                  cols="12"
                  class="mt-2"
                >
                  <b-table
                    ref="table-crop-details"
                    hover
                    responsive
                    show-empty
                    :fields="tableStoreSettings.fields"
                    :items="filteredStores"
                  >
                    <template #cell(index)="data">
                      {{ tableStoreSettings.currentPage * tableStoreSettings.perPage - tableStoreSettings.perPage + (data.index + 1) }}
                    </template>

                    <template #cell()="data">
                      <div class="text-nowrap">
                        {{ data.value }}
                      </div>
                    </template>

                    <template #cell(actions)="row">
                      <div class="text-nowrap">
                        <b-button
                          size="sm"
                          variant="success"
                          class="mr-2"
                          :disabled="state.storeEditing"
                          @click="onStoreEdit(row.item)"
                        >
                          Edit
                        </b-button>

                        <b-button
                          v-if="isNaN(parseInt(row.item.id))"
                          size="sm"
                          variant="danger"
                          :disabled="state.storeEditing"
                          @click="onStoreRemove(row.item)"
                        >
                          Remove
                        </b-button>
                      </div>
                    </template>
                  </b-table>
                </b-col>
              </b-row>
            </b-tab>
          </b-tabs>
        </form>
      </ValidationObserver>

      <template #modal-footer="{ok, cancel}">
        <b-button
          variant="success"
          :disabled="(state.busy || state.representativeEditing || state.storeEditing)"
          @click="ok()"
        >
          {{ state.editing ? 'Update Record' : 'Save Record' }}
        </b-button>
        <b-button
          variant="danger"
          :disabled="state.busy"
          @click="cancel()"
        >
          Cancel
        </b-button>
      </template>
    </b-modal>

    <b-modal
      id="modal-import-data"
      scrollable
      no-close-on-backdrop
      title="Import Data"
      @ok="onConfirmImport"
    >
      <ValidationObserver ref="form">
        <form
          role="form"
          novalidate
        >
          <div class="row">
            <div class="col-12">
              <ValidationProvider
                #default="{ errors }"
                name="cluster"
                vid="cluster"
                rules="required"
              >
                <b-form-group>
                  <label for="cluster">Cluster</label>
                  <v-select
                    id="cluster"
                    v-model="selected2.cluster"
                    :options="list.clusters"
                    :class="`${errors.length > 0 ? 'is-invalid' : ''}`"
                    label="cluster_name"
                  >
                    <template #option="{ cluster_name }">
                      <strong>{{ cluster_name }}</strong>
                    </template>
                    <template #no-options="">
                      No Available Clusters
                    </template>
                  </v-select>
                  <div
                    v-if="errors.length > 0"
                    class="invalid-feedback"
                  >
                    <span>{{ errors[0] }}</span>
                  </div>
                </b-form-group>
              </ValidationProvider>
            </div>

            <div class="col-12">
              <ValidationProvider
                #default="{ errors }"
                name="area"
                vid="area"
                rules="required"
              >
                <b-form-group>
                  <label for="area">Area</label>
                  <v-select
                    id="area"
                    v-model="selected2.area"
                    :options="list.areas"
                    :class="`${errors.length > 0 ? 'is-invalid' : ''}`"
                    label="area_name"
                  >
                    <template #option="{ area_name }">
                      <strong>{{ area_name }}</strong>
                    </template>
                    <template #no-options="">
                      No Available Areas
                    </template>
                  </v-select>
                  <div
                    v-if="errors.length > 0"
                    class="invalid-feedback"
                  >
                    <span>{{ errors[0] }}</span>
                  </div>
                </b-form-group>
              </ValidationProvider>
            </div>

            <div class="col-12">
              <ValidationProvider
                #default="{ errors }"
                name="distribution"
                vid="distribution"
                rules="required"
              >
                <b-form-group>
                  <label for="distribution">Distribution</label>
                  <v-select
                    id="distribution"
                    v-model="selected2.distribution"
                    :options="list.distributions"
                    :class="`${errors.length > 0 ? 'is-invalid' : ''}`"
                    label="distribution_name"
                  >
                    <template #option="{ distribution_name }">
                      <strong>{{ distribution_name }}</strong>
                    </template>
                    <template #no-options="">
                      No Available Distributions
                    </template>
                  </v-select>
                  <div
                    v-if="errors.length > 0"
                    class="invalid-feedback"
                  >
                    <span>{{ errors[0] }}</span>
                  </div>
                </b-form-group>
              </ValidationProvider>
            </div>

            <div class="col-12">
              <ValidationProvider
                #default="{ errors }"
                name="file"
                vid="file"
                rules="required"
              >
                <b-form-group>
                  <label for="file">Attach File: </label>
                  <b-form-file
                    v-model="excelData.file"
                    :class="`form-control ${errors.length > 0 ? 'is-invalid' : ''}`"
                    accept=".xlsx"
                  />
                  <div
                    v-if="errors.length > 0"
                    class="invalid-feedback"
                  >
                    <span>{{ errors[0] }}</span>
                  </div>
                </b-form-group>
              </ValidationProvider>
            </div>
          </div>
        </form>
      </ValidationObserver>

      <template #modal-footer="{ ok, cancel }">
        <b-button
          variant="success"
          :disabled="(state.busy || excelData.file === null || list.invalidData.length>0 || state.uploading)"
          @click="ok()"
        >
          Upload
        </b-button>
        <b-button
          variant="danger"
          :disabled="state.busy"
          @click="cancel()"
        >
          Cancel
        </b-button>
      </template>
    </b-modal>

    <!-- modal -->
    <b-modal
      id="modal-invalid-excel-data"
      title="Invalid Data"
      size="xl"
      scrollable
      no-close-on-backdrop
    >
      <b-table
        ref="tableInvalidData"
        hover
        responsive
        :per-page="tableInvalidData.perPage"
        :current-page="tableInvalidData.currentPage"
        :items="list.invalidData"
        :fields="tableInvalidData.fields"
        :sort-by.sync="tableInvalidData.sortBy"
        :sort-desc.sync="tableInvalidData.sortDesc"
        :sort-direction="tableInvalidData.sortDirection"
        :filter="tableInvalidData.filter"
        :filter-included-fields="tableInvalidData.filterOn"
        show-empty
      >
        <template #cell(index)="data">
          {{ tableInvalidData.currentPage * tableInvalidData.perPage - tableInvalidData.perPage + (data.index + 1) }}
        </template>

        <template #cell(line_error)="data">
          <div class="text-nowrap text-danger">
            {{ data.item.line_error }}
          </div>
        </template>

        <template #cell()="data">
          <div class="text-nowrap">
            {{ data.value }}
          </div>
        </template>
      </b-table>

      <b-row>
        <b-col
          cols="12"
          sm="6"
        >
          <div class="w-100 w-sm-25 mb-2 sm-mb-2">
            <b-select
              v-model="tableInvalidData.perPage"
              :options="tableInvalidData.pageOptions"
              size="sm"
            />
          </div>
        </b-col>
        <b-col
          cols="12"
          sm="6"
          class="d-flex justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="tableInvalidData.currentPage"
            :total-rows="tableInvalidData.totalRows"
            :per-page="tableInvalidData.perPage"
            first-number
            last-number
            pills
            prev-text="Prev"
            next-text="Next"
            aria-controls="table"
          />
        </b-col>
      </b-row>

      <template #modal-footer="{ cancel }">
        <b-button
          variant="danger"
          @click="cancel()"
        >
          Close Window
        </b-button>
      </template>
    </b-modal>
  </b-container>
</template>
<script>
import _ from 'lodash'
import { core } from '@/config/pluginInit'
import { AxiosError, AdminDealerService, AdminImportService, SharedListService } from '@/services'
import readXlsxFile from 'read-excel-file'
import formatter from '@/mixins/formatter'
import misc from '@/mixins/misc'

export default {
  name: 'AdminDealers',

  middleware: ['auth', 'admin'],

  metaInfo () {
    return {
      title: 'Dealers'
    }
  },

  mixins: [formatter, misc],

  data () {
    return {
      state: {
        busy: false,
        editing: false,
        uploading: false,
        representativeEditing: false,
        storeEditing: false
      },
      filter: {
        cluster: 'all',
        area: 'all'
      },
      list: {
        invalidData: [],
        clusters: [],
        areas: [],
        filter_areas: [],
        distributions: [],
        provinces: [],
        municipalities_one: [],
        municipalities_two: [],
        municipalities_three: [],
        barangays_one: [],
        barangays_two: [],
        barangays_three: []
      },
      options: {
        statuses: [
          { text: 'Active', value: 'Active' },
          { text: 'Dormant', value: 'Dormant' },
          { text: 'Closed', value: 'Closed' },
          { text: 'Refused', value: 'Refused' },
          { text: 'Hold', value: 'Hold' }
        ]
      },
      selected: {
        filter_cluster: null,
        filter_area: null,
        current: null,
        cluster: null,
        area: null,
        distribution: null,
        province_one: null,
        municipality_one: null,
        barangay_one: null,
        province_two: null,
        municipality_two: null,
        barangay_two: null
      },
      selected2: {
        cluster: null,
        area: null,
        distribution: null
      },
      selected3: {
        current: null,
        province: null,
        municipality: null,
        barangay: null
      },
      dealer: {
        active: 0,
        id: 0,
        status: null,
        partner_code: '',
        partner_name: '',
        business_address: '',
        province_one: null,
        municipality_one: null,
        barangay_one: null,
        home_address: '',
        province_two: null,
        municipality_two: null,
        barangay_two: null,
        distribution: null,
        cluster: null,
        area: null,
        representatives: [],
        dirty_representatives: [],
        stores: [],
        dirty_stores: []
      },
      representative: {
        id: 0,
        active: 0,
        name: '',
        birth_date: null,
        phone_one: '',
        phone_two: '',
        mobile_one: '',
        mobile_two: '',
        viber: '',
        email: '',
        created_at: null,
        updated_at: null
      },
      store: {
        id: 0,
        active: 0,
        store_name: '',
        store_address: '',
        province_id: 0,
        province: null,
        municipality_id: 0,
        municipality: null,
        barangay_id: 0,
        barangay: null,
        created_at: null,
        updated_at: null
      },
      excelData: {
        file: null,
        cluster: null,
        area: null,
        distribution: null,
        list: []
      },
      tableSettings: {
        perPage: 10,
        pageOptions: [10, 25, 50, 100],
        totalRows: 0,
        currentPage: 1,
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        filter: '',
        filterOn: [],
        fields: [
          'index',
          'actions',
          { key: 'created_at', formatter: this.dateTimeFormatter, sortable: true },
          { key: 'status' },
          { key: 'partner_code', sortable: true },
          { key: 'partner_name', sortable: true },
          { key: 'active', label: 'IS ACTIVE?' },
          { key: 'updated_at', formatter: this.dateTimeFormatter, sortable: true }
        ]
      },
      tableRepresetativeSettings: {
        perPage: 10,
        pageOptions: [10, 25, 50, 100],
        totalRows: 0,
        currentPage: 1,
        sortBy: '',
        sortDesc: true,
        sortDirection: 'desc',
        filter: '',
        filterOn: [],
        fields: [
          'index',
          'actions',
          { key: 'created_at', formatter: this.dateTimeFormatter, sortable: true },
          { key: 'name' },
          { key: 'phone_one', label: 'phone 1' },
          { key: 'phone_two', label: 'phone 2' },
          { key: 'mobile_one', label: 'mobile 1' },
          { key: 'mobile_two', label: 'mobile 2' },
          { key: 'email' },
          { key: 'viber' },
          { key: 'updated_at', formatter: this.dateTimeFormatter }
        ]
      },
      tableStoreSettings: {
        perPage: 10,
        pageOptions: [10, 25, 50, 100],
        totalRows: 0,
        currentPage: 1,
        sortBy: '',
        sortDesc: true,
        sortDirection: 'desc',
        filter: '',
        filterOn: [],
        fields: [
          'index',
          'actions',
          { key: 'created_at', formatter: this.dateTimeFormatter, sortable: true },
          { key: 'store_name' },
          { key: 'province.province_name', label: 'province' },
          { key: 'municipality.municipality_name', label: 'municipality' },
          { key: 'barangay.barangay_name', label: 'barangay' },
          { key: 'updated_at', formatter: this.dateTimeFormatter }
        ]
      },
      tableInvalidData: {
        perPage: 10,
        pageOptions: [10, 25, 50, 100],
        totalRows: 0,
        currentPage: 1,
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        filter: '',
        filterOn: [],
        fields: [
          'index',
          { key: 'partner_code' },
          { key: 'partner_name' },
          { key: 'address' },
          { key: 'line_number' },
          { key: 'line_error' }
        ]
      }
    }
  },

  computed: {
    modalTitle () {
      return this.state.editing ? 'Edit Dealer' : 'Add Dealer'
    },

    filteredRepresentatives () {
      return this.dealer.representatives.filter(item => item.active === 1)
    },

    representativeButtonLabel () {
      if (this.state.representativeEditing) {
        return 'Update'
      }

      return 'Insert'
    },

    representativeButtonVariant () {
      if (this.state.representativeEditing) {
        return 'secondary'
      }

      return 'success'
    },

    representativeButtonDisabledState () {
      // return !(this.representative.name.length > 0 && this.representative.mobile_one.length > 0)
      return !(this.representative.name.length > 0)
    },

    filteredStores () {
      return this.dealer.stores.filter(item => item.active === 1)
    },

    storeButtonLabel () {
      if (this.state.storeEditing) {
        return 'Update'
      }

      return 'Insert'
    },

    storeButtonVariant () {
      if (this.state.storeEditing) {
        return 'secondary'
      }

      return 'success'
    },

    storeButtonDisabledState () {
      return !(this.store.store_name.length > 0 && this.store.store_address.length > 0)
    }
  },

  watch: {
    'selected.filter_cluster' (value) {
      this.filter.cluster = value?.id || null
      this.getFilterAreas(this.filter.cluster)
      this.onFilterChanged()
    },

    'selected.filter_area' (value) {
      this.filter.area = value?.id || null
      this.onFilterChanged()
    },

    'selected.province_one' (value) {
      this.dealer.province_one = value?.id || null
      this.getMunicipalities(this.dealer.province_one)
    },

    'selected.municipality_one' (value) {
      this.dealer.municipality_one = value?.id || null
      this.getBarangays(this.dealer.municipality_one)
    },

    'selected.barangay_one' (value) {
      this.dealer.barangay_one = value?.id || null
    },

    'selected.province_two' (value) {
      this.dealer.province_two = value?.id || null
      this.getMunicipalitiesTwo(this.dealer.province_two)
    },

    'selected.municipality_two' (value) {
      this.dealer.municipality_two = value?.id || null
      this.getBarangaysTwo(this.dealer.municipality_two)
    },

    'selected.barangay_two' (value) {
      this.dealer.barangay_two = value?.id || null
    },

    'selected.cluster' (value) {
      this.dealer.cluster = value?.id || null
      this.getAreas(this.dealer.cluster)
    },

    'selected.area' (value) {
      this.dealer.area = value?.id || null
    },

    'selected.distribution' (value) {
      this.dealer.distribution = value?.id || null
    },

    'selected2.cluster' (value) {
      this.excelData.cluster = value?.id || null
      this.getAreas(this.excelData.cluster)
    },

    'selected2.area' (value) {
      this.excelData.area = value?.id || null
    },

    'selected2.distribution' (value) {
      this.excelData.distribution = value?.id || null
    },

    'selected3.province' (value) {
      this.store.province_id = value?.id || null
      this.getMunicipalitiesThree(this.store.province_id)
    },

    'selected3.municipality' (value) {
      this.store.municipality_id = value?.id || null
      this.getBarangaysThree(this.store.municipality_id)
    },

    'selected3.barangay' (value) {
      this.store.barangay_id = value?.id || null
    },

    'excelData.file' (value) {
      if (value) {
        this.onParseExcelFile(value)
      }
    },

    'dealer.representatives' (value) {
      this.tableRepresetativeSettings.totalRows = value?.length || 0
    },

    'dealer.stores' (value) {
      this.tableStoreSettings.totalRows = value?.length || 0
    },

    'list.invalidData' (value) {
      this.tableInvalidData.totalRows = value?.length || 0
    }
  },

  mounted () {
    core.index()
    this.getClusters()
    this.getDistributions()
    this.getProvinces()
  },

  methods: {
    async myProvider (ctx) {
      try {
        const { data } = await AdminDealerService.get(
          this.objectToUrl({
            page: ctx.currentPage,
            per_page: ctx.perPage,
            sort: ctx.sortBy,
            sort_desc: ctx.sortDesc,
            filter_text: ctx.filter,
            filter_cluster: this.filter.cluster,
            filter_area: this.filter.area
          })
        )
        this.tableSettings.totalRows = data.total_rows
        return data.items
      } catch {
        return []
      }
    },

    async getClusters () {
      await SharedListService.getClusters().then(({ data }) => {
        this.list.clusters = data
      })
    },

    async getAreas (cluster) {
      this.selected.area = null
      this.selected2.area = null
      if (cluster) {
        await SharedListService.getAreas(`cluster_id=${cluster}`).then(({ data }) => {
          this.list.areas = data
          if (this.state.editing) {
            if (this.selected.current.area) {
              this.selected.area = {
                id: this.selected.current.area.id,
                area_name: this.selected.current.area.area_name
              }
              this.selected.current.area = null
            }
          }
        })
      } else {
        this.list.areas = []
      }
    },

    async getFilterAreas (cluster) {
      this.selected.filter_area = null
      if (cluster) {
        this.list.filter_areas = []
        await SharedListService.getAreas(`cluster_id=${cluster}`).then(({ data }) => {
          this.list.filter_areas = data
        })
      } else {
        this.list.filter_areas = []
      }
    },

    async getDistributions () {
      await SharedListService.getDistributions().then(({ data }) => {
        this.list.distributions = data
      })
    },

    async getProvinces () {
      await SharedListService.getProvinces().then(({ data }) => {
        this.list.provinces = data
      })
    },

    async getMunicipalities (province) {
      this.selected.municipality_one = null
      if (province) {
        await SharedListService.getMunicipalities(`province_id=${province}`).then(({ data }) => {
          this.list.municipalities_one = data
          if (this.state.editing) {
            if (this.selected.current.municipality_one) {
              this.selected.municipality_one = {
                id: this.selected.current.municipality_one.id,
                municipality_name: this.selected.current.municipality_one.municipality_name
              }
              this.selected.current.municipality_one = null
            }
          }
        })
      } else {
        this.list.municipalities_one = []
      }
    },

    async getBarangays (municipality) {
      this.selected.barangay_one = null
      if (municipality) {
        await SharedListService.getBarangays(`municipality_id=${municipality}`).then(({ data }) => {
          this.list.barangays_one = data
          if (this.state.editing) {
            if (this.selected.current.barangay_one) {
              this.selected.barangay_one = {
                id: this.selected.current.barangay_one.id,
                barangay_name: this.selected.current.barangay_one.barangay_name
              }
              this.selected.current.barangay_one = null
            }
          }
        })
      } else {
        this.list.barangays_one = []
      }
    },

    async getMunicipalitiesTwo (province) {
      this.selected.municipality_two = null
      if (province) {
        await SharedListService.getMunicipalities(`province_id=${province}`).then(({ data }) => {
          this.list.municipalities_two = data
          if (this.state.editing) {
            if (this.selected.current.municipality_two) {
              this.selected.municipality_two = {
                id: this.selected.current.municipality_two.id,
                municipality_name: this.selected.current.municipality_two.municipality_name
              }
              this.selected.current.municipality_two = null
            }
          }
        })
      } else {
        this.list.municipalities_two = []
      }
    },

    async getBarangaysTwo (municipality) {
      this.selected.barangay_two = null
      if (municipality) {
        await SharedListService.getBarangays(`municipality_id=${municipality}`).then(({ data }) => {
          this.list.barangays_two = data
          if (this.state.editing) {
            if (this.selected.current.barangay_two) {
              this.selected.barangay_two = {
                id: this.selected.current.barangay_two.id,
                barangay_name: this.selected.current.barangay_two.barangay_name
              }
              this.selected.current.barangay_two = null
            }
          }
        })
      } else {
        this.list.barangays_two = []
      }
    },

    async getMunicipalitiesThree (province) {
      this.selected3.municipality = null
      if (province) {
        await SharedListService.getMunicipalities(`province_id=${province}`).then(({ data }) => {
          this.list.municipalities_three = data
          if (this.state.storeEditing) {
            if (this.selected3.current.municipality) {
              this.selected3.municipality = {
                id: this.selected3.current.municipality.id,
                municipality_name: this.selected3.current.municipality.municipality_name
              }
              this.selected3.current.municipality = null
            }
          }
        })
      } else {
        this.list.municipalities_three = []
      }
    },

    async getBarangaysThree (municipality) {
      this.selected3.barangay = null
      if (municipality) {
        await SharedListService.getBarangays(`municipality_id=${municipality}`).then(({ data }) => {
          this.list.barangays_three = data
          if (this.state.storeEditing) {
            if (this.selected3.current.barangay) {
              this.selected3.barangay = {
                id: this.selected3.current.barangay.id,
                barangay_name: this.selected3.current.barangay.barangay_name
              }
            }
            this.selected3.current.barangay = null
          }
        })
      } else {
        this.list.barangays_three = []
      }
    },

    onShowAddForm () {
      this.state.editing = false
      this.dealer.id = 0
      this.dealer.active = 1
      this.dealer.status = null
      this.dealer.partner_code = ''
      this.dealer.partner_name = ''
      this.dealer.business_address = ''
      this.dealer.home_address = ''
      this.dealer.representatives = []
      this.dealer.stores = []
      this.selected.current = null
      this.selected.cluster = null
      this.selected.area = null
      this.selected.distribution = null
      this.selected.province_one = null
      this.selected.municipality_one = null
      this.selected.barangay_one = null
      this.selected.province_two = null
      this.selected.municipality_two = null
      this.selected.barangay_two = null
      this.onRepresentativeReset()
      this.onStoreReset()

      this.$bvModal.show('modal-dealer-form')
    },

    onShowEditForm (current) {
      this.state.editing = true
      this.selected.current = { ...current }
      this.selected.cluster = null
      this.selected.area = null
      this.selected.distribution = null
      this.selected.province_one = null
      this.selected.municipality_one = null
      this.selected.barangay_one = null
      this.selected.province_two = null
      this.selected.municipality_two = null
      this.selected.barangay_two = null
      this.dealer.active = current.active
      this.dealer.id = current.id
      this.dealer.status = current.status
      this.dealer.partner_code = current.partner_code
      this.dealer.partner_name = current.partner_name
      this.dealer.business_address = current.business_address
      this.dealer.home_address = current.home_address
      this.dealer.representatives = current.representatives
      this.dealer.stores = current.stores

      if (current.cluster) {
        this.selected.cluster = {
          id: current.cluster.id,
          cluster_name: current.cluster.cluster_name
        }
      }

      if (current.distribution) {
        this.selected.distribution = {
          id: current.distribution.id,
          distribution_name: current.distribution.distribution_name
        }
      }

      if (current.province_one) {
        this.selected.province_one = {
          id: current.province_one.id,
          province_name: current.province_one.province_name
        }
      }

      if (current.province_two) {
        this.selected.province_two = {
          id: current.province_two.id,
          province_name: current.province_two.province_name
        }
      }

      // reset form
      this.onRepresentativeReset()
      this.onStoreReset()

      this.$bvModal.show('modal-dealer-form')
    },

    onShowImportDataForm () {
      this.selected2.cluster = null
      this.selected2.area = null
      this.selected2.distribution = null
      this.excelData.file = null
      this.list.invalidData = []
      this.$bvModal.show('modal-import-data')
    },

    async onParseExcelFile (data) {
      await readXlsxFile(data).then(excelRow => {
        this.excelData.data = []

        if (excelRow.length <= 1) {
          return 'No entries found'
        }

        if (
          excelRow[0][0] !== 'partner_code' ||
          excelRow[0][1] !== 'partner_name' ||
          excelRow[0][2] !== 'business_address' ||
          excelRow[0][3] !== 'home_address'
        ) {
          return 'Column Titles must be (partner_code, partner_name, business_address, home_address)'
        }

        if (excelRow.length >= 1000) {
          return 'Entries must not exceed beyond 1000'
        }

        // reset list
        this.list.invalidData = []
        this.excelData.list = []

        for (let i = 1; i <= excelRow.length - 1; i++) {
          let isOk = true
          let error = ''

          if (
            excelRow[i][0] === null ||
            excelRow[i][1] === null
          ) {
            isOk = false
            error = 'row value cannot be empty.'
          }

          if (isOk) {
            if (String(excelRow[i][0]).length !== 7) {
              isOk = false
              error = 'partner_code should be 7 characters.'
            }
          }

          if (isOk) {
            if (String(excelRow[i][1]).length > 200) {
              isOk = false
              error = 'partner_name should contain maximum of 200 characters only.'
            }
          }

          if (isOk) {
            if (String(excelRow[i][2]).length > 300) {
              isOk = false
              error = 'business_address should contain maximum of 300 characters only.'
            }
          }

          if (isOk) {
            if (String(excelRow[i][3]).length > 300) {
              isOk = false
              error = 'home_address should contain maximum of 300 characters only.'
            }
          }

          if (isOk) {
            this.excelData.list.push({
              partner_code: excelRow[i][0],
              partner_name: excelRow[i][1],
              business_address: excelRow[i][2],
              home_address: excelRow[i][3],
              line_number: i + 1,
              line_error: ''
            })
          } else {
            this.list.invalidData.push({
              partner_code: excelRow[i][0],
              partner_name: excelRow[i][1],
              business_address: excelRow[i][2],
              home_address: excelRow[i][3],
              line_number: i + 1,
              line_error: error
            })
          }
        }

        if (this.list.invalidData.length > 0) {
          this.$bvModal.show('modal-invalid-excel-data')
        }

        return null
      })
        .then(error => {
          if (error) {
            this.$refs.form.setErrors({
              file: [error]
            })
          }
        })
        .catch(() => {
          this.$refs.form.setErrors({
            file: ['There was a problem reading your uploaded excel file.']
          })
        })
    },

    async onConfirmImport (bvModalEvt) {
      bvModalEvt.preventDefault()
      await this.$refs.form.validate().then(async success => {
        if (success) {
          this.$swal.fire({
            icon: 'question',
            title: 'Question',
            text: 'Do you really want to import data?',
            confirmButtonColor: '#06C270',
            confirmButtonText: 'Yes',
            cancelButtonColor: '#FF2929',
            showCancelButton: true,
            showLoaderOnConfirm: true,
            preConfirm: () => {
              return this.onUploadFormPostSubmit()
            },
            allowOutsideClick: () => !this.$swal.isLoading()
          })
        } else {
          this.$bvModal.msgBoxOk('Oops! There were problem with your inputs.', {
            title: 'Validation Error',
            size: 'sm',
            buttonSize: 'sm',
            okVariant: 'danger',
            centered: true
          })
        }
      })
    },

    async onUploadFormPostSubmit () {
      return new Promise(resolve => {
        this.state.uploading = true
        AdminImportService.importDealers(this.excelData).then(({ data }) => {
          this.state.uploading = false
          this.$bvModal.hide('modal-import-data')
          this.list.invalidData = data.invalid_data

          if (data.total_uploaded === data.total_valid) {
            this.$swal.fire({
              icon: 'success',
              title: 'Successful',
              text: data.message,
              confirmButtonColor: '#06C270',
              confirmButtonText: 'Dismiss'
            }).then(() => {
              this.$refs.table.refresh()
            })
          }

          if (data.total_uploaded === data.total_invalid) {
            this.$swal.fire({
              icon: 'warning',
              title: 'Warning',
              text: 'Oppss! All the raw data you uploaded in invalid.',
              confirmButtonColor: '#06C270',
              confirmButtonText: 'Dismiss'
            }).then(() => {
              this.$bvModal.show('modal-invalid-excel-data')
            })
          }

          if (parseInt(data.total_valid) > 0 && parseInt(data.total_invalid) > 0) {
            this.$swal.fire({
              icon: 'warning',
              title: 'Warning',
              text: 'Oppss! Some of the raw data you uploaded is invalid.',
              confirmButtonColor: '#06C270',
              confirmButtonText: 'Dismiss'
            }).then(() => {
              this.$refs.table.refresh()
              this.$bvModal.show('modal-invalid-excel-data')
            })
          }
        })
          .catch(error => {
            this.state.uploading = false
            if (error instanceof AxiosError) {
              if (error.code === 422) {
                this.$refs.form.setErrors(error.message)
                resolve(error)
              }
            }
          })
      })
    },

    async onConfirmAction (bvModalEvt) {
      bvModalEvt.preventDefault()
      await this.$refs.form.validate().then(async success => {
        if (success) {
          let title = 'Do you really want to create dealer?'
          if (this.state.editing) {
            title = 'Do you really want to save changes?'
          }
          this.$swal.fire({
            icon: 'question',
            title: 'Question',
            text: title,
            confirmButtonColor: '#06C270',
            confirmButtonText: 'Yes',
            cancelButtonColor: '#FF2929',
            showCancelButton: true,
            showLoaderOnConfirm: true,
            preConfirm: () => {
              if (this.state.editing) {
                return this.onFormPutSubmit()
              } else {
                return this.onFormPostSubmit()
              }
            },
            allowOutsideClick: () => !this.$swal.isLoading()
          })
        } else {
          this.$bvModal.msgBoxOk('Oops! There were problem with your inputs.', {
            title: 'Validation Error',
            size: 'sm',
            buttonSize: 'sm',
            okVariant: 'danger',
            centered: true
          })
        }
      })
    },

    async onFormPostSubmit () {
      return new Promise(resolve => {
        this.state.busy = true
        AdminDealerService.post(this.dealer).then(({ data }) => {
          this.state.busy = false
          this.$bvModal.hide('modal-dealer-form')
          this.$swal.fire({
            icon: 'success',
            title: 'Successful',
            text: data.message,
            confirmButtonColor: '#06C270',
            confirmButtonText: 'Dismiss'
          }).then(() => {
            this.$refs.table.refresh()
          })
        }).catch(error => {
          this.state.busy = false
          if (error instanceof AxiosError) {
            if (error.code === 422) {
              this.$refs.form.setErrors(error.message)
              this.$bvModal.msgBoxOk('Oops! There were problem with your inputs.', {
                title: 'Validation Error',
                size: 'sm',
                buttonSize: 'sm',
                okVariant: 'danger',
                centered: true
              })
              resolve(error)
            }
          }
        })
      })
    },

    async onFormPutSubmit () {
      return new Promise(resolve => {
        this.state.busy = true
        AdminDealerService.put(this.dealer).then(({ data }) => {
          this.$bvModal.hide('modal-dealer-form')
          this.state.busy = false
          this.$swal.fire({
            icon: 'success',
            title: 'Successful',
            text: data.message,
            confirmButtonColor: '#06C270',
            confirmButtonText: 'Dismiss'
          }).then(() => {
            const row = _.find(this.$refs.table.localItems, { id: data.dealer.id })
            row.active = data.dealer.active
            row.status = data.dealer.status
            row.partner_code = data.dealer.partner_code
            row.partner_name = data.dealer.partner_name
            row.cluster = data.dealer.cluster
            row.area = data.dealer.area
            row.business_address = data.dealer.business_address
            row.province_one = data.dealer.province_one
            row.municipality_one = data.dealer.municipality_one
            row.barangay_one = data.dealer.barangay_one
            row.home_address = data.dealer.home_address
            row.province_two = data.dealer.province_two
            row.municipality_two = data.dealer.municipality_two
            row.barangay_two = data.dealer.barangay_two
            row.representatives = data.representatives
            row.stores = data.stores
            row.updated_at = data.dealer.updated_at
          })
        }).catch(error => {
          this.state.busy = false
          if (error instanceof AxiosError) {
            if (error.code === 422) {
              this.$refs.form.setErrors(error.message)
              this.$bvModal.msgBoxOk('Oops! There were problem with your inputs.', {
                title: 'Validation Error',
                size: 'sm',
                buttonSize: 'sm',
                okVariant: 'danger',
                centered: true
              })
              resolve(error)
            }
          }
        })
      })
    },

    onRepresentativeReset () {
      this.representative.id = 0
      this.representative.active = 0
      this.representative.name = ''
      this.representative.birth_date = null
      this.representative.phone_one = ''
      this.representative.phone_two = ''
      this.representative.mobile_one = ''
      this.representative.mobile_two = ''
      this.representative.viber = ''
      this.representative.email = ''
      this.representative.created_at = null
      this.representative.updated_at = null
    },

    onRepresentativeButtonClick () {
      if (this.state.representativeEditing) {
        this.onRepresentativeUpdate()
        return
      }

      this.onRepresentativeInsert()
    },

    onRepresentativeInsert () {
      // && this.representative.mobile_one.length > 0
      if (!(this.representative.name.length > 0)) {
        return
      }

      const newId = this.generateId(10)

      this.representative.id = newId
      this.representative.active = 1

      this.dealer.representatives.push({
        ...this.representative
      })

      this.onRepresentativeReset()
    },

    onRepresentativeEdit (current) {
      this.state.representativeEditing = true
      this.representative.id = current.id
      this.representative.active = current.active
      this.representative.name = current.name
      this.representative.birth_date = current.birth_date
      this.representative.phone_one = current.phone_one
      this.representative.phone_two = current.phone_two
      this.representative.mobile_one = current.mobile_one
      this.representative.mobile_two = current.mobile_two
      this.representative.email = current.email
      this.representative.viber = current.viber
    },

    onRepresentativeUpdate () {
      const representative = _.find(this.dealer.representatives, { id: this.representative.id })

      if (representative) {
        representative.name = this.representative.name
        representative.birth_date = this.representative.birth_date
        representative.phone_one = this.representative.phone_one
        representative.phone_two = this.representative.phone_two
        representative.mobile_one = this.representative.mobile_one
        representative.mobile_two = this.representative.mobile_two
        representative.email = this.representative.email
        representative.viber = this.representative.viber
      }

      // add to dirty list
      if (_.indexOf(this.dealer.dirty_representatives, this.representative.id) < 0) {
        this.dealer.dirty_representatives.push(this.representative.id)
      }

      this.state.representativeEditing = false
      this.onRepresentativeReset()
    },

    onRepresentativeCancelUpdate () {
      this.state.representativeEditing = false
      this.onRepresentativeReset()
    },

    onRepresentativeRemove (current) {
      if (!isNaN(parseInt(current.id))) {
        return
      }

      this.dealer.representatives = _.remove(this.dealer.representatives, function (o) {
        return o.id.toString() !== current.id.toString()
      })
    },

    onStoreReset () {
      this.state.storeEditing = false
      this.store.id = 0
      this.store.active = 0
      this.store.store_name = ''
      this.store.store_address = ''
      this.store.created_at = null
      this.store.updated_at = null
      this.selected3.province = null
      this.selected3.municipality = null
      this.selected3.barangay = null
    },

    onStoreButtonClick () {
      if (this.state.storeEditing) {
        this.onStoreUpdate()
        return
      }

      this.onStoreInsert()
    },

    onStoreInsert () {
      if (!(this.store.store_name.length > 0 && this.store.store_address.length > 0)) {
        return
      }

      const newId = this.generateId(10)

      this.store.id = newId
      this.store.active = 1
      this.store.province = _.find(this.list.provinces, { id: this.store.province_id })
      this.store.municipality = _.find(this.list.municipalities_three, { id: this.store.municipality_id })
      this.store.barangay = _.find(this.list.barangays_three, { id: this.store.barangay_id })

      this.dealer.stores.push({
        ...this.store
      })

      // console.log(this.dealer.stores)

      this.onStoreReset()
    },

    onStoreEdit (current) {
      // console.log(current)
      this.state.storeEditing = true
      this.selected3.current = { ...current }
      this.store.id = current.id
      this.store.store_name = current.store_name
      this.store.store_address = current.store_address
      this.store.active = current.active
      this.store.created_at = current.created_at
      this.store.updated_at = current.updated_at

      if (current.province_one) {
        this.selected3.province = {
          id: current.province_one.id,
          province_name: current.province_one.province_name
        }
      }
    },

    onStoreUpdate () {
      const store = _.find(this.dealer.stores, { id: this.store.id })

      if (store) {
        store.store_name = this.store.store_name
        store.store_address = this.store.store_address
        store.province = _.find(this.list.provinces, { id: this.store.province_id })
        store.municipality = _.find(this.list.municipalities_three, { id: this.store.municipality_id })
        store.barangay = _.find(this.list.barangays_three, { id: this.store.barangay_id })
      }

      // add to dirty list
      if (_.indexOf(this.dealer.dirty_stores, this.store.id) < 0 && !isNaN(this.store.id)) {
        this.dealer.dirty_stores.push(this.store.id)
      }

      this.state.storeEditing = false
      this.onStoreReset()
    },

    onStoreCancelUpdate () {
      this.state.storeEditing = false
      this.onStoreReset()
    },

    onStoreRemove (current) {
      if (!isNaN(parseInt(current.id))) {
        return
      }

      this.dealer.stores = _.remove(this.dealer.stores, function (o) {
        return o.id.toString() !== current.id.toString()
      })
    },

    onFilterChanged () {
      this.$refs.table.refresh()
    }
  }
}
</script>
